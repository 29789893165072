import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getExactComplaints(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getExactComplaintsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async changeComplaintStatus(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changeComplaintStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'id' : id },{ headers: header });// make api call
        return response.data;
    },
    async getExactReports(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getExactReportsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async changeReportStatus(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changeReportStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'id' : id },{ headers: header });// make api call
        return response.data;
    },
    async addToQuacksList(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addToQuacksListUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getQuacks(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getQuacksUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async removeQuackFromList(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.removeQuackFromListUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getSearchedQuacks(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSearchedQuacksUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async deleteComplaintData(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteComplaintDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : id },{ headers: header });// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}