import axios from 'axios';

const state = {
    
};
 
const getters = { 
    
};

const actions = {
    async getPaymentItems(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPaymentItemsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getPaginatedPaymentItems(state, url) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        // const url = state.rootState.url.getPaymentItemsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {},{ headers: header });// make api call 
        return response.data;
    },
    async updatePaymentBreakdown(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updatePaymentBreakdownUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async deletePaymentBreakdown(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deletePaymentBreakdownUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updatePaymentSurcharge(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updatePaymentSurchargeUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getEmailItems(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getEmailItemsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updateEmail(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateEmailUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getSpecializations(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSpecializationsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updateSpecialization(state, data) {// update Specialization
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateSpecializationUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getPaginatedSpecializations(state, url) {// get paginated Specializations
        const authorizationToken = state.rootState.url.authorizationToken;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {},{ headers: header });// make api call 
        return response.data;
    },
    async deleteUserSpecialization(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserSpecializationUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updateSubSpecialization(state, data) {// update Specialization
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateSubSpecializationUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getAQconfigs(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAQconfigsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async searchAQconfigs(state, key) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.searchAQconfigsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {'key' : key},{ headers: header });// make api call 
        return response.data;
    },
    async updateQualification(state, data) {// update Specialization
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateQualificationUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async deleteUserQualification(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserQualificationUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getCountries(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getCountriesUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updateCountry(state, data) {// update Specialization
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateCountryUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async deleteUserCountry(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserCountryUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getStates(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getStatesUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updateState(state, data) {// update Specialization
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateStateUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async deleteUserState(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserStateUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updateLga(state, data) {// update Specialization
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateLgaUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async deleteUserLga(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserLgaUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getAppointmentPostings(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAppointmentPostingsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updatePostingDepartment(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updatePostingDepartmentUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async deleteAppointmentPosting(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAppointmentPostingUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getSiteParameters(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSiteParametersUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updateSiteParameter(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateSiteParameterUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async searchSpecializations(state, key) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.searchSpecializationsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {'key' : key},{ headers: header });// make api call 
        return response.data;
    },
    async searchCountries(state, key) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.searchCountriesUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {'key' : key},{ headers: header });// make api call 
        return response.data;
    },
    async getLetterItems(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getLetterItemsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, data,{ headers: header });// make api call 
        return response.data;
    },
    async updateLetter(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateLetterUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, data,{ headers: header });// make api call 
        return response.data;
    },
};

const mutations = {
    
};

export default {
    state,
    getters,
    actions,
    mutations
}