<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />
        <sidebar-item 
          :link="{
            name: 'User Profile', 
            icon: 'ni ni-single-02 text-yellow', 
            path: '/profile'
          }
        "/>
          
<!-- v-show="menu && menu.privileges.view == 'yes'" -->
        <sidebar-item 
          v-for="menu in menus"
          v-show="getPriviledge(menu)"
          :key="menu.id"
          :link="{name: menu.name , icon: menu.icon , path: '/'+menu.link, children: menu.has_child,
                  id: menu.id }"
        />

        <!-- <sidebar-item :link="{name: 'Maps', icon: 'ni ni-pin-3 text-orange', path: '/maps'}"/>
        <sidebar-item :link="{name: 'User Profile', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/>
        <sidebar-item :link="{name: 'Tables', icon: 'ni ni-bullet-list-67 text-red', path: '/tables'}"/>
        <sidebar-item :link="{name: 'Login', icon: 'ni ni-key-25 text-info', path: '/login'}"/> -->

        <sidebar-item :link="{name: 'Logout', icon: 'ni ni-user-run text-pink', path: '/logout'}"/>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view :key="$route.fullPath"></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    computed: {
      ...mapGetters(['menus', 'user'])
    },
    methods: {
      ...mapActions(['fetchUserProfile']),
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      getPriviledge(menu) {// get priviledge
        let isHod = this.user.is_hod == 'yes';// if user is HOD
        let UserMenu = menu.id == 4;// get User Mgt Menu
        let data = menu.privileges;
        return (isHod && UserMenu) || (data && data.view && data.view == 'yes') ? true : false;
      }
    },
    mounted() {
      this.fetchUserProfile(); // get user details
    }
  };
</script>
<style lang="scss">
</style>
