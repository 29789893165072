import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getTrainingSchools(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingSchoolsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async enableSchoolEdit(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.enableSchoolEditUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getTrainingSchool(state, key) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingSchoolUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { key: key },{ headers: header });// make api call
        return response.data;
    },
    async updateSchoolProfile(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateSchoolProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deleteSchoolAcount(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteSchoolAcountUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : id },{ headers: header });// make api call
        return response.data;
    },
    async getSchoolApplications(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSchoolApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getSchoolApplication(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSchoolApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : id },{ headers: header });// make api call
        return response.data;
    },
    async approveSchoolApplication(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveSchoolApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getAccreditedSchools(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAccreditedSchoolsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async modifyDeleteSchool(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.modifyDeleteSchoolUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getAdvisorySchools(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAdvisorySchoolsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async modifyDeleteAdvisorySchool(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.modifyDeleteAdvisorySchoolUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getTrainingSchoolPayments(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingSchoolPaymentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getIndexingApplications(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getIndexingApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getIndexingApplication(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getIndexingApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async updateIndexingStudent(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateIndexingStudentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async approveSchoolList(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveSchoolListUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getTrainingSchoolsReporting(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingSchoolsReportingUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getIndexingReporting(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getIndexingReportingUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async notifySchoolVisitation(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.notifySchoolVisitationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async updateIndexingSerialNo(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateIndexingSerialNoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}