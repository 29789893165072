import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getPendingSignups(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.pendingSignupsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getPaginatedPendingSignups(state, url) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {},{ headers: header });// make api call
        return response.data;
    },
    async updatePendingSignups(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updatePendingSignupsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}