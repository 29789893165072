import Vuex from 'vuex';
import Vue from 'vue';
import user from './modules/user';
import url from './modules/url/url';
import signups from './modules/signups/signups';
import application from './modules/application/application';
import doctor from './modules/doctor/doctor';
import setting from './modules/setting/setting';
import payment from './modules/payment/payment';
import insurance from './modules/insurance/insurance';
import otherSettings from './modules/setting/otherSettings';
import reporting from './modules/reporting/reporting';
import discipline from './modules/discipline/discipline';
import school from './modules/schools/schools';
import inspectorate from './modules/inspectorate/inspectorate';
import assessment from './modules/assessment/assessment';
import hospital from './modules/hospital/hospital';
import notification from './modules/notification/notification';
import provider from './modules/provider/provider';
import trainingSchool from './modules/trainingSchool/trainingSchool';
import attachment from './modules/attachment/attachment';
import ticketing from './modules/ticketing/ticketing';
import remediation from './modules/remediation/remediation';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        user,
        url,
        signups,
        application,
        doctor,
        setting,
        payment,
        insurance,
        otherSettings,
        reporting,
        discipline,
        school,
        inspectorate,
        assessment,
        hospital,
        notification,
        provider,
        trainingSchool,
        attachment,
        ticketing,
        remediation
    }
});

