import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getAUser(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAUserUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async updateUserProfile(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateUserProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getUserAddQual(state, id) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserAddQualUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async getAdditionalQulifications(state) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAdditionalQulificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async getInstitutions(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getInstitutionsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        return response.data;
    },
    async enableUserEdit(state, id) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.enableUserEditUrl;
        const adminId = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : id, 'admin_id' : adminId},
                            { headers: header });// make api call
        return response.data;
    },
    async getRegistrationTypes(state) {//
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRegistrationTypesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        return response.data;
    },
    async getSubRegistrations(state) {//
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSubRegistrationTypesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        return response.data;
    },
    async updateAdditionalQualification(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateAdditionalQualificationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getUserApplications(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getPaginatedUserApplications(state, url) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {},{ headers: header });// make api call
        return response.data;
    },
    async getUserMedicalSchool(state, id) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserMedicalSchoolUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : id},{ headers: header });// make api call
        return response.data;
    },
    async updateMedicalSchoolRecord(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateMedicalSchoolRecordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async deleteUserAcount(state, id) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserAcountUrl;
        const adminId = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : id, 'admin_id' : adminId},
                            { headers: header });// make api call
        return response.data;
    },
    async deleteUserAq(state, id) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserAqUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async getExactInstitutions(state, id) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getExactInstitutionsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{ id: id }, { headers: header });// make api call
        return response.data;
    },
    async resetDoctorPassword(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.resetDoctorPasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deleteAnApplication(state, id) {// delete user application
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAnApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let data = { id : id, admin_id : state.rootState.user.userId };
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}