import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async addAssessmentBatch(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addAssessmentBatchUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getBatches(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getBatchesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getBatchPayments(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getBatchPaymentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'id' : id },{ headers: header });// make api call
        return response.data;
    },
    async addAssessmentBatchPayment(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addAssessmentBatchPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deleteBatchPayment(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteBatchPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'id' : id },{ headers: header });// make api call
        return response.data;
    },
    async getBatchPaymentBreakdowns(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getBatchPaymentBreakdownsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'id' : id },{ headers: header });// make api call
        return response.data;
    },
    async addPaymentBreakdown(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addPaymentBreakdownUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deleteBatchPaymentBreakdown(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteBatchPaymentBreakdownUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'id' : id },{ headers: header });// make api call
        return response.data;
    },
    async completeAssessmentBatch(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.completeAssessmentBatchUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'id' : id },{ headers: header });// make api call
        return response.data;
    },
    async deleteAssessmentBatch(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAssessmentBatchUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async changeBatchStatus(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changeBatchStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getSearchedBatches(state, key) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSearchedBatchesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'key' : key },{ headers: header });// make api call
        return response.data;
    },
    async getAssessmentTypes(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentTypesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async addAssessmentType(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addAssessmentTypeUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deleteAssessmentType(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAssessmentTypeUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getAssessmentApplications(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getAssessmentApplicant(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentApplicantUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'id' : id },{ headers: header });// make api call
        return response.data;
    },
    async approveAssessmentApplication(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveAssessmentApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async saveAssessmentApplicantData(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveAssessmentApplicantDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async addPreviousAssessmentEntry(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addPreviousAssessmentEntryUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getPreviousAssessmentEntries(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPreviousAssessmentEntriesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deletePreviousAssessmentBatch(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deletePreviousAssessmentBatchUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getAssessmentPayments(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAssessmentPaymentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async changeSeatStatus(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changeSeatStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}