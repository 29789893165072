import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async createHospital(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createHospitalUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getHospitals(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getHospitalsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deleteHospital(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteHospitalUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async changeHospitalStatus(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changeHospitalStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getSearchedHospitals(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSearchedHospitalsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getTrainingHospitalApplications(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingHospitalApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getHospitalApplication(state, id) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getHospitalApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: id }, { headers: header});// make api call
        return response.data;
    },
    async approveHospitalApplication(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveHospitalApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getAccreditedHospitals(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAccreditedHospitalsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async updateAccreditedHospital(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateAccreditedHospitalUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deleteAccreditedHospital(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAccreditedHospitalUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getTrainingHospitals(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingHospitalsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async changeTrainingHospitalStatus(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changeTrainingHospitalStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getTrainingHospitalPayments(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingHospitalPaymentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getTrainingHospitalsReporting(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingHospitalsReportingUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async notifyHospitalVisitation(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.notifyHospitalVisitationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getTrainingHospital(state, key) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTrainingHospitalUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { key: key },{ headers: header });// make api call
        return response.data;
    },
    async updateHospitalProfile(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateHospitalProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async enableHospitalEdit(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.enableHospitalEditUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async deleteHospitalAcount(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteHospitalAcountUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { id : id },{ headers: header });// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}