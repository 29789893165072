import axios from 'axios';

const state = {
    
};
 
const getters = { 
    
};

const actions = {
    async getAdminUnits(state) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAdminUnitsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {},{ headers: header });// make api call 
        return response.data;
    },
    async getUnitMembers(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUnitMembersUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {'id' : id},{ headers: header });// make api call 
        return response.data;
    },
    async getMemberPrivileges(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getMemberPrivilegesUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, data,{ headers: header });// make api call 
        return response.data;
    },
    async updateMemberPrivilege(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateMemberPrivilegeUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getAdminDepartments(state) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAdminDepartmentsUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {},{ headers: header });// make api call 
        return response.data;
    },
    async saveDepartment(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveDepartmentUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async deleteAdminDepartment(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAdminDepartmentUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async saveUnit(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveUnitUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async deleteAdminUnit(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteAdminUnitUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async updateUserUnit(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateUserUnitUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async enableAdminUser(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.enableAdminUserUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getAdminUsers(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAdminUsersUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async getPaginatedAdminUsers(state, url) {// get admin users
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url,{},{ headers: header });// make api call 
        return response.data;
    },
    async createAdminUser(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createAdminUserUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
    async searchAdminUser(state, key) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.searchAdminUserUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, { 'key' : key },{ headers: header });// make api call 
        return response.data;
    },
    async getDepartmentMembers(state, id) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getDepartmentMembersUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {'id' : id},{ headers: header });// make api call 
        return response.data;
    },
    async makeUserHod(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createAdminUserUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
};

const mutations = {
    
};

export default {
    state,
    getters,
    actions,
    mutations
}