import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getRemediationBatches(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemediationBatchesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async addRemediationBatch(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addRemediationBatchUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getRemediationApplications(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemediationApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getRemediationApplication(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemediationApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async approveRemediationApplication(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveRemediationApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}