import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited. applications/additional-pgq/old
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        {
          path: '/new-signups',
          name: 'New Signups',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewSignup/NewSignup.vue')
        },
        {
          path: '/new-temp-signup',
          name: 'New Temporary Doctor Signups',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewSignup/NewTempSignup.vue')
        },
        {
          path: '/change-password',
          name: 'Change Password',
          component: () => import(/* webpackChunkName: "demo" */ './views/ChangePassword.vue')
        },
        {
          path: '/profile',
          name: 'User Profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/update/profile',
          name: 'Update Profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UpdateProfile.vue')
        },
        {
          path: '/doctors',
          name: 'Doctors',
          component: () => import(/* webpackChunkName: "demo" */ './views/Doctors/Doctors.vue')
        },
        {
          path: '/applications/:code',
          name: 'All Applications',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/Application.vue')
        },
        {
          path: '/old-additional-pgq/:code',
          name: 'Old Additional Qualifications',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/OldAddQualifications/OldAddQualifications.vue')
        },
        {
          path: '/old-additional-pgq/details/:id',
          name: 'Old Additional Qualifications Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/OldAddQualifications/OldAQDetails.vue')
        },
        {
          path: '/applications/:code/:id',
          name: 'Application Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Application/ApplicationDetail/ApplicationDetail.vue')
        },
        {
          path: '/payments/add-payment',
          name: 'Add Payment',
          component: () => import(/* webpackChunkName: "demo" */ './views/Payments/AddPayment.vue')
        },
        {
          path: '/payments/master-add-payment',
          name: 'Master Add Payment',
          component: () => import(/* webpackChunkName: "demo" */ './views/Payments/MasterAddPayment.vue')
        },
        {
          path: '/payments/remove-payment',
          name: 'Remove Payment',
          component: () => import(/* webpackChunkName: "demo" */ './views/Payments/RemovePayment.vue')
        },
        {
          path: '/insurance-indemnity',
          name: 'Insurance Indemnity',
          component: () => import(/* webpackChunkName: "demo" */ './views/Insurance/Insurance.vue')
        },
        {
          path: '/settings/:code',
          name: 'Settings',
          component: () => import(/* webpackChunkName: "demo" */ './views/Settings/Settings.vue')
        },
        {
          path: '/reporting/:code',
          name: 'Reporting',
          component: () => import(/* webpackChunkName: "demo" */ './views/Reporting/Reporting.vue')
        },
        {
          path: '/discipline/:code',
          name: 'Discipline',
          component: () => import(/* webpackChunkName: "demo" */ './views/Discipline/Discipline.vue')
        },
        {
          path: '/schools/:code',
          name: 'Schools',
          component: () => import(/* webpackChunkName: "demo" */ './views/Schools/Schools.vue')
        },
        {
          path: '/exit-register',
          name: 'Exit Register',
          component: () => import(/* webpackChunkName: "demo" */ './views/ExitRegister/ExitRegister.vue')
        },
        {
          path: '/exit-register-reports',
          name: 'Exit Register Reports',
          component: () => import(/* webpackChunkName: "demo" */ './views/ExitRegister/ExitRegisterReports/ExitRegisterReport.vue')
        },
        {
          path: '/complaint-requests',
          name: 'Complaints against doctors',
          component: () => import(/* webpackChunkName: "demo" */ './views/Inspectorate/Complaints/Complaints.vue')
        },
        {
          path: '/quack-reports',
          name: 'Report Quacks',
          component: () => import(/* webpackChunkName: "demo" */ './views/Inspectorate/ReportQuacks/ReportQuacks.vue')
        },
        {
          path: '/view-quacks',
          name: 'Quacks',
          component: () => import(/* webpackChunkName: "demo" */ './views/Inspectorate/Quacks/Quacks.vue')
        },
        {
          path: '/assessment/:code',
          name: 'Assessment Exam',
          component: () => import(/* webpackChunkName: "demo" */ './views/Assessment/Assessment.vue')
        },
        {
          path: '/assessment-application/:id',
          name: 'Assessment Application Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Assessment/AssessmentApplicationDetails.vue')
        },
        {
          path: '/assessment-exam-cards/:id',
          name: 'Assessment Exam Cards',
          component: () => import(/* webpackChunkName: "demo" */ './views/Assessment/AssessmentExamCard/AssessmentExamCard.vue')
        },
        {
          path: '/hospitals/:code',
          name: 'Teaching Hospitals',
          component: () => import(/* webpackChunkName: "demo" */ './views/Hospitals/Hospitals.vue')
        },
        {
          path: '/notifications/:code',
          name: 'Notifications',
          component: () => import(/* webpackChunkName: "demo" */ './views/Notifications/Notifications.vue')
        },
        {
          path: '/training-hospitals/:code',
          name: 'Training Hospitals',
          component: () => import(/* webpackChunkName: "demo" */ './views/TrainingHospitals/TrainingHospitals.vue')
        },
        {
          path: '/training-hospitals/applications/:id',
          name: 'Training Hospital Application Detail',
          component: () => import(/* webpackChunkName: "demo" */ './views/TrainingHospitals/ApplicationDetail/ApplicationDetail.vue')
        },
        {
          path: '/cpd/:code',
          name: 'CPD Providers',
          component: () => import(/* webpackChunkName: "demo" */ './views/CpdProviders/CpdProviders.vue')
        },
        {
          path: '/cpd/application-details/:id',
          name: 'CPD Application Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/CpdProviders/ApplicationDetail/ApplicationDetail.vue')
        },
        {
          path: '/training-schools/:code',
          name: 'Training Schools',
          component: () => import(/* webpackChunkName: "demo" */ './views/TrainingSchools/TrainingSchools.vue')
        },
        {
          path: '/training-schools/application-details/:id',
          name: 'Training School Application Detail',
          component: () => import(/* webpackChunkName: "demo" */ './views/TrainingSchools/ApplicationDetail/ApplicationDetail.vue')
        },
        {
          path: '/indexing/:code',
          name: 'Indexing',
          component: () => import(/* webpackChunkName: "demo" */ './views/Indexing/Indexing.vue')
        },
        {
          path: '/indexing/application-details/:id',
          name: 'School Indexing Application Detail',
          component: () => import(/* webpackChunkName: "demo" */ './views/Indexing/ApplicationDetail/ApplicationDetail.vue')
        },
        {
          path: '/attachment/:code',
          name: 'Attachment Program',
          component: () => import(/* webpackChunkName: "demo" */ './views/Attachment/Attachment.vue')
        },
        {
          path: '/attachment/attachment-applications/:id',
          name: 'Attachment Program Application Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Attachment/AttachmentApplicationDetails/AttachmentApplicationDetails.vue')
        },
        {
          path: '/induction-ticketing/:code',
          name: 'Induction Ticketing',
          component: () => import(/* webpackChunkName: "demo" */ './views/InductionTicketing/InductionTicketing.vue')
        },
        {
          path: '/induction-ticketing/inductees/:id',
          name: 'Ticket Batch Inductees',
          component: () => import(/* webpackChunkName: "demo" */ './views/InductionTicketing/TicketInductees/TicketInductees.vue')
        },
        {
          path: '/verification/:code',
          name: 'Verification',
          component: () => import(/* webpackChunkName: "demo" */ './views/Verification/Verification.vue')
        },
        {
          path: '/remediation/:code',
          name: 'Remediation Programme',
          component: () => import(/* webpackChunkName: "demo" */ './views/Remediation/Remediation.vue')
        },
        {
          path: '/mwan/payment/:code',
          name: 'MWAN Payment',
          component: () => import(/* webpackChunkName: "demo" */ './views/MwanPayment/MwanPayment.vue')
        },
        // {
        //   path: '/icons',
        //   name: 'Discipline',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue')
        // },
        {
          path: '/logout',
          name: 'logout',
          component: () => import(/* webpackChunkName: "demo" */ './views/Logout.vue')
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'Admin Login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        // {
        //   path: '/register',
        //   name: 'register',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        // },
        {
          path: '/password-reset',
          name: 'password reset',
          component: () => import(/* webpackChunkName: "demo" */ './views/PasswordReset.vue')
        },
        {
          path: '/update-password',
          name: 'Update Password',
          component: () => import(/* webpackChunkName: "demo" */ './views/UpdatePassword.vue')
        }
      ],
      meta: {
        requiresVisitor: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let saved = localStorage.getItem('time_out');// set access token
    if (saved && (saved < new Date().getTime())) {//session time out
        localStorage.clear();
        window.location.reload();
    }
    if (! store.getters.isLoggedIn) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.isLoggedIn) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router;
