import axios from 'axios';

// const rootUrl = 'https://api.portal.mdcn.gov.ng'; 

const state = {
    user: {},
    accessToken: localStorage.getItem('access_token') || null,
    userId: localStorage.getItem('user_id') || null,
    menus: [],
    dashbardInfo: {}
};
 
const getters = { 
    user: state => state.user,    
    getUserAccessToken: state => state.accessToken,
    isLoggedIn: state => state.accessToken != null && state.userId != null ? true : false,
    menus: state => state.menus,
    dashbardInfo: state => state.dashbardInfo,
};

const actions = {
    async fetchUserProfile(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.profileUserUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {'id' : state.state.userId}, 
                { headers: header });// make api call 
        state.commit('setMenus', response.data.menus);
        state.commit('setUser', response.data.user);
        return response.data.user;
    },
    destroyAccessToken(state) {// destroy local storage        
        localStorage.removeItem('access_token');// remove token
        localStorage.removeItem('user_id');// remove user id
        localStorage.removeItem('time_out');// remove time out
        return state.accessToken = null;
    },
    storeAndLoginUser(state, data) {//set token and login user
        let hours = 1;
        localStorage.setItem('access_token', data.access_token);// set access token
        localStorage.setItem('user_id', data.user_id);// set user id    
        localStorage.setItem('time_out', new Date().getTime() + (hours * 60 * 60 * 1000));// set timeout
    }, 
    async getAdminDashboardInfo(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.dashboardInfoUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {},{ headers: header });// make api call 
        state.commit('setDashbardInfo', response.data);
        return response.data;
    },
    async changePassword(state, data) {// get user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changePasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async resetAdminPassword(state, data) {// get user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.resetAdminPasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;        
    }
};

const mutations = {
    setMenus: (state, menus) => (state.menus = menus),// set menus only
    setUser: (state, user) => (state.user = user),// set user profile
    setDashbardInfo: (state, data) => (state.dashbardInfo = data)// set Dashbard Info
};

export default {
    state,
    getters,
    actions,
    mutations
}