import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getApplicationTypes(state) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationTypesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {},{ headers: header });// make api call
        return response.data;
    },
    async getUserProfile(state, folio) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { 'folio' : folio },{ headers: header });// make api call
        return response.data;
    },
    async getRemitaPaymentStatus(state, rrr) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemitaPaymentStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const data = { rrr : rrr, type : 'admin', is_url : true, admin_id : state.rootState.user.userId };
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async updateApplicationTransaction(state, data) {// update payment transaction
        data.admin_id = state.rootState.user.userId;// add admin id
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateApplicationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async loadRRRDetails(state, rrr) {// update payment transaction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.loadRRRDetailsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'payment_ref' : rrr}, { headers: header});// make api call
        return response.data;
    },
    async removeRRRPayment(state, rrr) {// update payment transaction 340365852461
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.removeRRRPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {
                'payment_ref' : rrr, 'admin_id' : state.state.userId
            }, { headers: header});// make api call
        return response.data;
    },
    async verifyMwanPayment(state, data) {// update payment transaction
        data.admin_id = state.rootState.user.userId;// add admin id
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.verifyMwanPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}