var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Argon","title":"Argon"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'User Profile', 
            icon: 'ni ni-single-02 text-yellow', 
            path: '/profile'
          }}}),_vm._l((_vm.menus),function(menu){return _c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.getPriviledge(menu)),expression:"getPriviledge(menu)"}],key:menu.id,attrs:{"link":{name: menu.name , icon: menu.icon , path: '/'+menu.link, children: menu.has_child,
                  id: menu.id }}})}),_c('sidebar-item',{attrs:{"link":{name: 'Logout', icon: 'ni ni-user-run text-pink', path: '/logout'}}})],2)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }