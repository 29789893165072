import axios from 'axios';

const state = {
    
};
 
const getters = { 
    
};

const actions = {
    async getUserInsuranceRecords(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getIndemnityUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;        
    },
    async updateInsuranceRecord(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateInsuranceRecordUrl;  
        const header = {Authorization: `Bearer ${authorizationToken}`}; 
        const response = await axios.post(url, {data},{ headers: header });// make api call 
        return response.data;
    },
};

const mutations = {
    
};

export default {
    state,
    getters,
    actions,
    mutations
}