<template>
    <li class="nav-item">
        <router-link
                :to="link.path == '/#' ? this.$route.path : link.path"
                @click.native="linkClick"

                class="nav-link"
                :target="link.target">
            <template>
                <i :class="link.icon"></i>
                <span
                  class="nav-link-text"
                  @click="displayChildren(link.id)"
                >
                  <strong>{{ link.name }}</strong>
                  &nbsp;
                  <i
                    class="fa fa-angle-right"
                    v-if="(link.children && link.children.length > 0) && ! showChildren"
                  ></i>
                  <i
                    class="fa fa-angle-down"
                    v-if="(link.children && link.children.length > 0) && showChildren"
                  ></i>
                </span>
            </template>
        </router-link>

        <div
          v-if="(link.children && link.children.length > 0) &&
                showChildren && (link.id == selectedMenu)"
        >
          <router-link
                v-for="child in link.children"
                :key="child.id"
                :to="'/'+child.link"
                @click.native="linkClick"
                class="nav-link margin40"
                :target="link.target"
                :href="child.link"
                v-show="(getPrivileges(child) && (getPrivileges(child).view == 'yes')) ||
                        (checkUserIsHod(child) && (checkUserIsHod(child).view == 'yes'))"
          >
          <!-- v-show="getPrivileges(child) && ((getPrivileges(child).view == 'yes') || checkUserIsHod(menu))" -->
            <template>
                <span class="nav-link-text">{{ child.name }}</span>
            </template>
          </router-link>
        </div>
    </li>
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'sidebar-item',
    props: {
      link: {
        type: Object,
        default: () => {
          return {
            name: '',
            path: '',
            children: []
          };
        },
        description:
          'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info'
      }
    },
    inject: {
      autoClose: {
        default: true
      }
    },
    data() {
      return {
        children: [],
        collapsed: true,
        showChildren: false,
        selectedMenu: null
      };
    },
    computed: {
      ...mapGetters(['user'])
    },
    methods: {
      checkUserIsHod(menu) {
        let isHod = this.user.is_hod == 'yes';// if user is HOD
        let UserMenu = menu.link == 'settings/units' || menu.link == 'settings/departments';// get User Mgt Menu
        let status = (isHod && UserMenu) ? 'yes' : 'no';
        return  { 'view' : status, 'add' : status, 'update' : status, 'delete' : status };
      },
      getPrivileges(menu) {  // get child menu privileges
        if(menu && this.link.children && this.link.children.length > 0) {
          for(let child of this.link.children) {
            if(child.id == menu.id) {
                return child.privileges;
            }
          }
        }
      },
      linkClick() {
        if (
          this.autoClose &&
          this.$sidebar &&
          this.$sidebar.showSidebar === true
        ) {
          this.$sidebar.displaySidebar(false);
        }
      },
      displayChildren(id) {
        this.selectedMenu = null;
        this.showChildren = ! this.showChildren;
        this.selectedMenu = id;
      }
    }
  };
</script>
<style scoped>
  .margin40 {
    margin-left: 50px;
  }
  .right {
    float: right;
  }
</style>