import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getPayments(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPaymentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getFilteredPayments(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getFilteredPaymentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getExportPayments(state, data) {// get admin units
        let url = state.rootState.url.rootUrl+'/'+data;
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {},{ headers: header });// make api call
        return response.data;
    },
    async getApplications(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getFilteredApplication(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getFilteredApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getAuditTrailRecords(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAuditTrailRecordsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getFilteredAuditTrailRecords(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getFilteredAuditTrailRecordsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getDoctorsProfile(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getDoctorsProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getFilteredDoctorsProfile(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getFilteredDoctorsProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getMedicalEducationPayments(state, data) {// get admin units
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getMedicalEducationPaymentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}